import { graphql, withPrefix } from 'gatsby';
import * as React from 'react';

import LazyLoad from 'react-lazyload';

import { Link } from 'gatsby';
import { css } from '@emotion/core';
import Helmet from 'react-helmet';

import Footer from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import PostCard from '../components/PostCard';
import ButtonLink from '../components/ui/ButtonLink';
import Wrapper from '../components/Wrapper';
import IndexLayout from '../layouts';
import config from '../website-config';
import {
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared';
import { PageContext } from '../templates/post';
import { colors } from '../styles/colors';

const HomePosts = css`

  @media (min-width: 795px) {
    .post-card:nth-of-type(6n + 1):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: 5px 0 0 5px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) h2 {
      font-size: 2.6rem;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) p {
      font-size: 1.8rem;
      line-height: 1.55em;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }
  }

  h2.latest {
    text-align: center;
    font-size: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .tools-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    text-align: center;
  }

  .tools-grid a {
    text-decoration: ${colors.beta} 1px underline;
    transition: text-decoration 0.25s;
  }

  .tools-grid a:hover {
    text-decoration: ${colors.alpha} 1px underline;
  }

  figcaption {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 650px) {
    h2.tools {
      font-size: 2.4rem;
    }
    .tools-grid {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }

  .tools-grid img {
    box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
    border-radius: 5px;
    transition: all 0.5s ease;
    margin-bottom: 2rem;
  }
  .tools-grid img:hover {
    transform: scale(1.03);
    box-shadow: rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px;
  }
`;

export interface IndexProps {
  data: {
    header: {
      childImageSharp: {
        fluid: any;
      };
    };
    allMarkdownRemark: {
      edges: {
        node: PageContext;
      }[];
    };
  };
}

const IndexPage: React.FunctionComponent<IndexProps> = (props) => {
  const width = props.data.header.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0];
  const height = String(Number(width) / props.data.header.childImageSharp.fluid.aspectRatio);
  return (
    <IndexLayout css={HomePosts}>
      <Helmet>
        <title>Photography and Photo Retouching Guides, Tips & Tricks &bull; Giggster Guide</title>
        <meta
          name="description"
          content="Giggster Guide is your new focal point to learn the ins and outs of digital photography and photo retouching with software like Abode Photoshop and Capture One Pro."
        />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={`${config.siteUrl}/`} />
        <meta
          property="og:image"
          content={`https://giggster.com/guide${props.data.header.childImageSharp.fluid.src}`}
        />
        <meta property="article:publisher" content={config.facebook} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={`${config.siteUrl}/`} />
        <meta
          name="twitter:image"
          content={`https://giggster.com/guide${props.data.header.childImageSharp.fluid.src}`}
        />
        <meta property="og:image:width" content={width} />
        <meta property="og:image:height" content={height} />
      </Helmet>
      <Wrapper>
        <header css={[outer, SiteHeader]} style={{  }}>
          <div css={inner}>
            <SiteHeaderContent>
              <SiteTitle>
                <div>
                  <svg className="logo" fill="#32ba55" width="32" height="32" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
                    <path d="M125.8 47.31a64.01 64.01 0 0 0-9.82-20.66 149.1 149.1 0 0 0-53.83 30.8c-4.77-3.6-9.67-7.02-14.7-10.24a169.15 169.15 0 0 0-15.69 16.85 212.24 212.24 0 0 1 32.07 24.19 127.32 127.32 0 0 1 40.7-32.52A41.41 41.41 0 0 1 64 105.36C41.36 105.57 22.43 86.4 22.64 64a41.41 41.41 0 0 1 54.72-39.15c7.97-4.67 16.43-8.71 25.11-12A63.85 63.85 0 0 0 64 0a63.9 63.9 0 0 0-45.25 18.75A63.9 63.9 0 0 0 0 64a63.9 63.9 0 0 0 18.75 45.25A63.9 63.9 0 0 0 64 128a63.88 63.88 0 0 0 45.25-18.75A63.88 63.88 0 0 0 128 64c0-5.7-.74-11.28-2.2-16.69Z" />
                  </svg>
                </div>
                Photography & Retouching
                <br />
                Tools, Resources, Guides, Tips & Tricks
              </SiteTitle>
            </SiteHeaderContent>
            <SiteNav isHome={true} />
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <div className="tools-grid">
              <Link to="/free-luts/" title="Free Photoshop LUTs">
                <figure>
                  <img alt="" src={withPrefix('/covers/free-luts-3.jpg')}/>
                  <figcaption>Free LUTs for Photoshop</figcaption>
                </figure>
              </Link>
              <Link to="/capture-one-styles/" title="Free Capture One Styles">
                <figure>
                  <img alt="" src={withPrefix("/covers/capture-one-styles-s.jpg")} />
                  <figcaption>25 Free Capture One Styles</figcaption>
                </figure>
              </Link>
              <Link to="/texture-overlays/" title="Textures to use as overlays on your images">
                <figure>
                  <img alt="" src={withPrefix("/covers/texture-overlays.jpg")} />
                  <figcaption>Free Texture Overlays for Photoshop</figcaption>
                </figure>
              </Link>
              <Link to="/light-leaks/" title="Free light leaks">
                <figure>
                  <img alt="" src={withPrefix("/covers/light-leaks.jpg")} />
                  <figcaption>22 Free Light Leaks</figcaption>
                </figure>
              </Link>
              <Link to="/bokeh-overlays/" title="220 Free Bokeh Overlays">
                <LazyLoad height={200}>
                  <figure>
                    <img alt="" src={withPrefix("/covers/bokeh-overlays.jpg")} />
                    <figcaption>220 Free Bokeh Overlays</figcaption>
                  </figure>
                </LazyLoad>
              </Link>
              <Link to="/color-grading/" title="Color Grading">
                <LazyLoad height={200}>
                  <figure>
                    <img alt="" src={withPrefix("/covers/color-grading-s.jpg")} />
                    <figcaption>Color Grading Helper with Photoshop Actions</figcaption>
                  </figure>
                </LazyLoad>
              </Link>
              <Link to="/duotone-generator/" title="Duotone Generator">
                <LazyLoad height={200}>
                  <figure>
                    <img alt="" src={withPrefix("/covers/duotone-generator-s.jpg")} />
                    <figcaption>Duotone Generator with Photoshop Actions</figcaption>
                  </figure>
                </LazyLoad>
              </Link>
              <Link
                to="/black-and-white-converter/"
                title="Convert images to black and white online"
              >
                <LazyLoad height={200}>
                  <figure>
                    <img alt="" src={withPrefix("/covers/black-and-white-converter-s.jpg")} />
                    <figcaption>Black & White Converter with Photoshop Actions</figcaption>
                  </figure>
                </LazyLoad>
              </Link>
            </div>
            <div className="text-center t3">
              <ButtonLink href={withPrefix("/tools/")}>All Resources &amp; Tools</ButtonLink>
            </div>

            <hr />

            <h2 className="latest text-center">Latest Articles &amp; Guides</h2>
            <div css={[PostFeed, PostFeedRaise]}>
              {props.data.allMarkdownRemark.edges.map((post) => {
                return <PostCard key={post.node.fields.slug} post={post.node} />;
              })}
            </div>
          </div>
  
        </main>
        {props.children}

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "img/blog-cover.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(limit: 1000, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            tags
            meta_description
            image {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
